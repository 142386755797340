<template>
	<div class="container">
		<back />
		<div class="header">
			<div style="margin-right: auto; margin-left: 150px">
				<el-button-group>
					<el-button type="primary" icon="el-icon-arrow-left" @click="changePage('/step/one')">上一步
					</el-button>
					<el-button type="primary" @click="changePage('/step/three')">下一步<i
							class="el-icon-arrow-right el-icon--right"></i>
					</el-button>
				</el-button-group>
			</div>
			<el-select v-model="value" placeholder="请选择" @change="changeMon()">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
		</div>

		<!-- 第一步 && 第二步 -->
		<div class="stepOne">
			<div class="box-left">
				<h3>
					第二步：组织评价
				</h3>
				<div class="img">
					<img src="https://oss.culturalcloud.net/furong/202211/05171810mdf3.png" alt="" />
				</div>
				<span style="font-size: 22px; text-align: center">月度会议中，员工每个人相互评价月度工作完成情况，工作完成纳入绩效考核。</span>
				<userMenu :userId="$route.params.useId" :nickName="$route.params.nickName"></userMenu>
			</div>
			<div class="box-right">
				<el-table border :data="tableData" :row-style="{fontSize:'30px'}" :header-cell-style="{ background: '#a46e39', color: '#fff' }" style="width: 100%;margin-bottom: 30px;">
					<el-table-column label="部门组织架构" >
						<template slot-scope="scope">
							<img width="150" height="150" :src="scope.row.organizationImg" alt="">
						</template>
					</el-table-column>

					<el-table-column prop="targetProfit" label="2023年目标利润额">
					</el-table-column>
					<el-table-column prop="cost" label="已产生费用">
					</el-table-column>
					<el-table-column prop="bonus" label="利润、奖金分配">
					</el-table-column>
				</el-table>
				<!-- <div class="btn">
					<el-button type="primary" size="mini" @click="openDialog()" icon="el-icon-plus">添加任务</el-button>
				</div> -->
				<el-table stripe :data="workList" border style="width: 100%;min-height: 650px;" :cell-style="{ fontSize: '18px' }" :header-cell-style="{ background: '#409EFF', color: '#fff' }"  max-height="650">
					<el-table-column label="工作任务"  prop="taskContent"></el-table-column>
					<el-table-column width="300" label="备注"  prop="remark"></el-table-column>
					<el-table-column width="120" label="完成情况">
						<template slot-scope="scope">
							<!-- {{scope.row.completion}} -->
							<el-tag :type="tagStatus(scope.row.completion).type">{{tagStatus(scope.row.completion).text }}</el-tag>
						</template>
					</el-table-column>
					<el-table-column width="120" label="完成时间" prop="completionTime"></el-table-column>
					
					<el-table-column width="230" label="组织评价" >
						<template slot-scope="scope">
							<el-rate :value="scope.row.score" @change="changRate($event, scope.row)"
								void-icon-class="iconfont icon-normal"
								:icon-classes="['iconfont icon-sad', 'iconfont icon-normal', 'iconfont icon-smile']"
								:colors="['#8290a2', '#F7BA2A', '#FF9900']">
							</el-rate>
						</template>
					</el-table-column>

				</el-table>

			</div>
		</div>
		<!-- 对话框 -->
		<el-dialog @close="resetForm('form')" :close-on-click-modal="false" :title="title" :visible.sync="open"
			width="500px" append-to-body>
			<el-form :model="form" ref="form" :rules="rules" label-width="80px">
				<el-form-item label="任务内容" prop="taskContent">
					<el-input :autosize="{ minRows: 3, maxRows: 15 }" type="textarea" v-model="form.taskContent"
						placeholder="请输入任务内容"></el-input>
				</el-form-item>
				<el-form-item label="完成时间" prop="completionTime">
					<el-date-picker value-format="yyyy-MM-dd " type="date" placeholder="请选择完成日期" v-model="form.completionTime"
						style="width: 100%;"></el-date-picker>
				</el-form-item>
				<el-form-item label="完成情况" prop="type">
					<el-select v-model="form.type" placeholder="请选择完成状态">
						<el-option :key="item.value" :label="item.label" :value="item.value" v-for="item in options2"></el-option>

					</el-select>
				</el-form-item>

				<el-form-item label="备注" prop="remark">
					<el-input :autosize="{ minRows: 3, maxRows: 15 }" type="textarea" v-model="form.remark"
						placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer">
				<el-button @click="resetForm('form')">重置</el-button>
				<el-button type="primary" @click="submitForm">确认提交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import {
	getWorkList,
	editWork,
	delWork,
	addWork,
	frameworkList
} from "@/api";
import back from "@/components/back";
import tableEdit from "@/components/table-edit";
export default {
	name: "work",
	components: {
		back,
		tableEdit
	},
	data() {
		return {
			checked: false,
			workList: [],
			rules: {
				taskContent: [{
					required: true,
					message: "任务内容不能为空",
					trigger: "blur"
				},],
				completionTime: [{
					required: true,
					message: "完成时间不能为空",
					trigger: "blur"
				},],
				type: [{
					required: true,
					message: "请选择完成状态",
					trigger: "blur"
				},],
			},
			title: "",
			open: false,
			form: {},
			tableData: [],
			panelList: [{
				text: "重要且紧急",
				color: "#C83434",
				type: 1,
				workList: [],
			},
			{
				text: "重要不紧急",
				color: "#FC9E30",
				type: 2,
				workList: [],
			},
			{
				text: "紧急不重要",
				color: "#34A4C8",
				type: 3,
				workList: [],
			},
			{
				text: "不重要不紧急",
				color: "#8ABF33",
				type: 4,
				workList: [],
			},
			],
			options: [],
			options2: [{
				value: 0,
				label: '进行中'
			}, {
				value: 1,
				label: '已完成'
			}, {
				value: 2,
				label: '未开始'
			}],
			value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1),


		};
	},
	methods: {
		tagStatus(status){
			switch (status) {
				case 0:
				return {
					text:'进行中...',
					type:''
				}
					break;
					case 1:
				return {
					text:'已完成',
					type:'success'
				}
					break;
					case 2:
				return {
					text:'未开始',
					type:'warning'
				}
					break;
			}
		},
		changRate(e, data) {
        data.score = e;
        editWork(data).then((res) => {
          this.$message({
            message: "打分更新成功!",
            type: "success",
          });
          this.getWorkData();
        });
      },

		getFrameworkList() {
			frameworkList(this.$route.params.userId).then(res => {
				if (res.code === 200) {
					// console.log(res);
					this.tableData = [res.data]
				}
			})
		},
		edit(e) {
			// console.log(e)
			editWork(e).then((res) => {
				this.$message({
					message: "修改任务成功!",
					type: "success",
				});
				this.getWorkData();
			});
		},

		//切换页面
		changePage(path) {
			this.$router.push(path + '/' + this.$route.params.userId + '/' + this.$route.params.nickName)
		},
		// 重置表单
		reset() {
			this.form = {
				taskId: null,
				userId: null,
				taskContent: null,
				type: null,
				remark: null,
			};
			this.resetForm("form");
		},
		// 勾选状态
		changeBox(e, data) {

			data.completion = Number(e);

			editWork(data).then((res) => {
				this.$message({
					message: "任务状态更新成功!",
					type: "success",
				});
				this.open = false;
				// this.reset()
				this.getWorkData();
			});
		},
		// 删除工作
		handleDelete(id) {
			this.$confirm("是否确认删除工作任务的数据项？", "警告", {
				confirmButtonText: "确认",
				cancelButtonText: "取消",
				type: "warning",
			}).then((res) => {
				delWork(id).then(() => {
					this.getWorkData();
					this.$message({
						message: "删除任务成功!",
						type: "success",
					});
				});
			});
		},
		// 添加打开
		openDialog() {
			this.form = {};
			this.open = true;
			this.form.userId = this.$route.params.userId;
			this.title = "添加工作任务";
		},
		// 提交表单
		submitForm() {
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if (this.form.taskId != null) {
						// 修改
						// console.log(this.form.taskId);
						editWork(this.form).then((res) => {
							this.$message({
								message: "修改任务成功!",
								type: "success",
							});
							this.open = false;
							this.reset();
							this.getWorkData();
						});
					} else {
						// 新增
						addWork(this.form).then((res) => {
							this.$message({
								message: "新增任务成功!",
								type: "success",
							});
							this.open = false;
							this.reset();
							this.getWorkData();
						});
					}
				}
			});
		},
		// 重置表单
		resetForm(formName) {
			let taskId = this.form.taskId
			this.form = {
				userId: this.$route.params.userId,
				taskId: taskId
			};
			this.$refs[formName].resetFields();
		},

		// 更新打开
		handleUpdate(data) {
			this.form = JSON.parse(JSON.stringify(data));
			this.open = true;
			this.title = "修改工作任务";
		},
		getWorkData() {
			// 更新数据前必须清空数据
			getWorkList({
				time: this.value,
				userId: this.$route.params.userId
			}).then(
				(res) => {
					if (res.code === 200) {
						// this.panelList.forEach(item =>{
						// 	let arr = res.data.filter(i =>{
						// 		return i.type == item.type
						// 	})
						this.workList = res.data
						this.options = res.month.map((item) => {
							return {
								value: item,
								label: item
							}
						})
						// console.log(arr);
						// })
					}
				}
			);
		},
		changeMon(e) {
			// console.log(e);
			this.getWorkData();
		},
	},
	created() {
		this.getWorkData();
		this.getFrameworkList()
	},
};
</script>

<style lang="scss" scoped>
 
 ::v-deep .iconfont {
    font-size: 30px;
  }
::v-deep .el-rate {
	height: auto;
}
::v-deep .el-table .cell {
	line-height: normal;
	white-space: pre-wrap;
	
}
.target {
	width: 100%;
	.col {
		font-size: 30px;
	}
}
.col {
	width: 100%;
	white-space: pre-wrap;
	// min-height: 40px;
	// line-height: 40px;
	// padding: 0 10px;
	// text-align: left;
	// display: flex;
	// align-items: center;
	// justify-content: flex-start;
	// border: 2px solid #a7a7a7;
	// box-sizing: border-box;
	// border-top: none;
	font-size: 18px;
	// font-family: monospace;
	// white-space: nowrap;
	// text-overflow: ellipsis;
	// overflow: hidden;

}

.container {
	background-color: #fffcfa;
	height: 100vh;
	padding: 30px;

	.header {
		// width: 100%;
		margin-top: 50px;
		display: flex;
		justify-content: flex-end;
		align-items: center;

	}

	.stepOne {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 950px;

		.box-left {
			// border: 1px solid #000;
			width: 30%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			h3 {
				font-size: 30px;
				width: 100%;
				text-align: center;
				line-height: 80px;
				background-color: #faf5f0;
				margin-bottom: 30px;
				font-weight: bold;
				color: #a46e39;
				border: 3px #a46e39 dashed;
				border-radius: 10px;
			}

			.img {
				width: 100%;

				img {
					width: 100%;
				}
			}
		}

		.box-right {
			width: 65%;

			// display: flex;

			// justify-content: space-between;
			// flex-wrap: wrap;
			.btn {
				margin: 20px 0;
				text-align: right;
			}

			.panel-box {
				border-radius: 5px;
				box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
				// border: 1px solid #000;
				width: 49%;
				height: 380px;
				margin-bottom: 20px;
				overflow: auto;

				.panel-header {
					padding: 0 20px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: sticky;
					top: 0;
					font-size: 22px;
					font-weight: bold;
					// background-color: #C83434;
					color: #fff;
					line-height: 50px;
					text-align: center;
					z-index: 999;

					span {
						display: flex;
						justify-content: center;
						align-items: center;

						i {
							font-size: 27px;
							color: rgba(0, 0, 0, .5);
						}
					}
				}

				.panel-container {
					height: calc(100% - 50px);
					overflow: hidden;
					background-color: #f5f6f7;

					.panel-content {
						background-color: rgb(227, 227, 227, .36);
						border-bottom: 1px solid #ccc;
						padding: 10px 20px;

						font-size: 20px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.title {
							// max-width: 350px;
							margin-right: auto;
							margin-left: 20px;
							cursor: pointer;
							// word-break: break-all;
							// text-overflow: ellipsis;
							// display: -webkit-box;
							// -webkit-box-orient: vertical;
							// -webkit-line-clamp: 2;
							// /* 这里是超出几行省略 */
							// overflow: hidden;
						}

						.active {
							text-decoration: line-through;
							color: #ccc;
						}
					}
				}

				.bg {
					background-color: aliceblue;
				}
			}
		}
	}
}
</style>
